import { SuccessLoginCallback } from 'authentication'
import { NotFoundPage } from 'components'
import { userRoles } from 'enums'
import Layout from 'layout/index'
import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

const Dashboard = lazy(() => import('pages/dashboard'))
const Partners = lazy(() => import('pages/partners'))
const PartnerDetails = lazy(() => import('pages/partners/partnerDetails'))
const Hospitals = lazy(() => import('pages/hospitals/'))
const Users = lazy(() => import('pages/users'))
const Devices = lazy(() => import('pages/devices'))
const UploadDevices = lazy(() => import('pages/devices/UploadDevices'))
const BlobStorageDeviceLog = lazy(() => import('pages/devices/device-details/deviceLogs/BlobStorageDeviceLog'))
const AppDeploymentShareLogs = lazy(() => import('pages/app-deployments/app-deployment-details/AppDeploymentShareLogs'))
const DeviceDetails = lazy(() => import('pages/devices/device-details'))
const OsDeployments = lazy(() => import('pages/os-deployments'))
const OsDeploymentsDetails = lazy(() => import('pages/os-deployments/deployment-details'))
const DeviceFlavour = lazy(() => import('pages/device-flavour'))
const DeviceApps = lazy(() => import('pages/deviceApps'))
const DeviceAppsDetails = lazy(() => import('pages/deviceApps/deviceapps-details'))
const Profiles = lazy(() => import('pages/profiles'))
const ProfileDetails = lazy(() => import('pages/profiles/profile-details'))
const EditProfile = lazy(() => import('pages/profiles/edit-profile-form/EditProfile'))
const HospitalFloors = lazy(() => import('pages/hospital-floors'))
const CreateOsDeployment = lazy(() => import('pages/os-deployments/create-os-deployment/CreateOsDeployment'))
const OSReleases = lazy(() => import('pages/os-releases'))
const AppReleases = lazy(() => import('pages/app-releases'))
const UploadMLModels = lazy(() => import('pages/upload-ml-models'))
const CreateMLModels = lazy(() => import('pages/create-ml-models'))
const MlDeployments = lazy(() => import('pages/ml-deployments'))
const AppDeployments = lazy(() => import('pages/app-deployments'))
const CreateAppDeployment = lazy(() => import('pages/app-deployments/create-app-deployment/CreateAppDeployment'))
const AppDeploymentDetails = lazy(() => import('pages/app-deployments/app-deployment-details'))

const AppRoutes = () => (
	<Routes>
		<Route element={<Layout roles={[userRoles.SUPPORT, userRoles.SUPER_USER, userRoles.DEVELOPER]} />}>
			<Route index element={<Dashboard />} />
			<Route path='devices' element={<Devices />} />
			<Route path='devices/:serial/:deviceId' element={<DeviceDetails />} />
		</Route>

		<Route element={<Layout roles={[userRoles.SUPER_USER, userRoles.DEVELOPER]} />}>
			<Route path='users' element={<Users />} />
			<Route path='devices/received-logs/:logId' element={<BlobStorageDeviceLog />} />
			<Route path='devices/devices-upload' element={<UploadDevices />} />
			<Route path='os-deployments' element={<OsDeployments />} />
			<Route path='os-deployments/:deploymentId' element={<OsDeploymentsDetails />} />
			<Route path='os-deployments/create-deployment' element={<CreateOsDeployment />} />
			<Route path='profiles' element={<Profiles />} />
			<Route path='profiles/:profileId' element={<ProfileDetails />} />
			<Route path='profiles/editProfile/:profileId' element={<EditProfile />} />
			<Route path='hospital-floors' element={<HospitalFloors />} />
			<Route path='ml-deployments' element={<MlDeployments />} />
			<Route path='os-releases' element={<OSReleases />} />
			<Route path='app-releases' element={<AppReleases />} />
			<Route path='companies' element={<Partners />} />
			<Route path='companies/:partnerId' element={<PartnerDetails />} />
			<Route path='device-flavors' element={<DeviceFlavour />} />
			<Route path='device-apps' element={<DeviceApps />} />
			<Route path='device-apps/:appId' element={<DeviceAppsDetails />} />
			<Route path='upload-ml-models' element={<UploadMLModels />} />
			<Route path='create-ml-models' element={<CreateMLModels />} />
			<Route path='hospitals' element={<Hospitals />} />
			<Route path='app-deployments' element={<AppDeployments />} />
			<Route path='app-deployments/create-app-deployment' element={<CreateAppDeployment />} />
			<Route path='app-deployments/:deploymentId' element={<AppDeploymentDetails />} />
			<Route path='app-deployments-logs/:logUrl' element={<AppDeploymentShareLogs />} />
		</Route>
		<Route path='*' element={<NotFoundPage />} />
		<Route path='/signin-oidc' element={<SuccessLoginCallback />} />
	</Routes>
)

export default AppRoutes
