import { Box, Heading, Text, Button } from '@chakra-ui/react'

export const NotAuthorizedPage = () => {
	return (
		<Box textAlign='center' py={10} px={6}>
			<Heading display='inline-block' as='h2' size='2xl' bgGradient='linear(to-r, teal.400, teal.600)' backgroundClip='text'>
				403
			</Heading>
			<Text fontSize='18px' mt={3} mb={2}>
				Not Authorized
			</Text>
			<Text color='gray.500' mb={6}>
				You are not authorized to visit this page, contact your administrator for further actions!
			</Text>
			<Button onClick={() => window.location.replace(window.location.origin)} colorScheme='teal' variant='solid'>
				Go to Home
			</Button>
		</Box>
	)
}
