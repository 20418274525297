import { userRoles } from 'enums'
import {
	AIFileIconOutline,
	AndroidIconOutline,
	AppAddIconOutline,
	AppReleaseIconOutline,
	AppsIconOutlined,
	CloudSyncIconOutline,
	CreateIconOutline,
	DashboardIconOutline,
	DeviceManagementIconOutline,
	DevicesIconOutline,
	FlavorIconOutline,
	FloorIconOutline,
	HeadLearningIconOutline,
	IconCompanyOutlined,
	IconHospitalOutlined,
	ProfileIconOutline,
	ReleaseIconOutline,
	SettingsIconOutline,
	UploadIconOutlined,
	UserCheckIconOutline,
	UserIconOutline,
} from 'icons/global'

export const newTabs = [
	{
		label: 'Dashboard',
		icon: DashboardIconOutline,
		path: '/',
		isParent: true,
		roles: [userRoles.SUPER_USER, userRoles.SUPPORT, userRoles.DEVELOPER],
	},
	{
		label: 'User Management',
		icon: UserCheckIconOutline,
		isParent: true,
		children: [
			{ label: 'Users', icon: UserIconOutline, path: 'users', roles: [userRoles.SUPER_USER] },
			{
				label: 'Companies',
				icon: IconCompanyOutlined,
				path: 'companies',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
			{
				label: 'Hospitals',
				icon: IconHospitalOutlined,
				path: 'hospitals',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
		],
		roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
	},
	{
		label: 'Provisioning',
		icon: SettingsIconOutline,
		isParent: true,
		children: [
			{
				label: 'Devices',
				icon: DevicesIconOutline,
				path: 'devices',
				roles: [userRoles.SUPER_USER, userRoles.SUPPORT, userRoles.DEVELOPER],
			},
			{ label: 'Profiles', icon: ProfileIconOutline, path: 'profiles', roles: [userRoles.SUPER_USER, userRoles.DEVELOPER] },
			{
				label: 'Hospital Floors',
				icon: FloorIconOutline,
				path: 'hospital-floors',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
		],
		roles: [userRoles.SUPER_USER, userRoles.DEVELOPER, userRoles.SUPPORT],
	},
	{
		label: 'OTA Deployment',
		icon: CloudSyncIconOutline,
		isParent: true,
		children: [
			{
				label: 'OS Deployments',
				icon: AndroidIconOutline,
				path: 'os-deployments',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
			{
				label: 'APP Deployments',
				icon: AppAddIconOutline,
				path: 'app-deployments',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
			{
				label: 'ML Model Deployments',
				icon: AIFileIconOutline,
				path: 'ml-deployments',
				roles: [userRoles.SUPER_USER],
			},
		],
		roles: [userRoles.SUPER_USER, userRoles.DEVELOPER, userRoles.SUPPORT],
	},
	{
		label: 'Device Management',
		icon: DeviceManagementIconOutline,
		isParent: true,
		children: [
			{
				label: 'Device Flavors',
				icon: FlavorIconOutline,
				path: 'device-flavors',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
			{ label: 'Applications', icon: AppsIconOutlined, path: 'device-apps', roles: [userRoles.SUPER_USER, userRoles.DEVELOPER] },
			{ label: 'OS Releases', icon: ReleaseIconOutline, path: 'os-releases', roles: [userRoles.SUPER_USER, userRoles.DEVELOPER] },
			{
				label: 'APP Releases',
				icon: AppReleaseIconOutline,
				path: 'app-releases',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
		],
		roles: [userRoles.SUPER_USER, userRoles.DEVELOPER, userRoles.SUPPORT],
	},
	{
		label: 'ML Models',
		icon: HeadLearningIconOutline,
		isParent: true,
		children: [
			{
				label: 'Create ML Models',
				icon: CreateIconOutline,
				path: 'create-ml-models',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
			{
				label: 'Upload ML Models',
				icon: UploadIconOutlined,
				path: 'upload-ml-models',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
		],
		roles: [userRoles.SUPER_USER],
	},
]
