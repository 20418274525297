import { Box, Link as ChakraLink, Text, useColorModeValue } from '@chakra-ui/react'
import { newTabs } from 'layout/routeTabs'
import { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const DASHBOARD = 'Dashboard'
const lightBlueColor = '#0078D4'

const SideBarList = ({ tabs }) => {
	const { pathname } = useLocation()
	const initialTab = newTabs.find((tab) => tab.children?.some((child) => pathname.includes(child.path)))?.label
	const [expandedTab, setExpandedTab] = useState(initialTab ?? DASHBOARD)

	const expandedTabColor = useColorModeValue('#F3F3F3', '#242424')
	const selectedTabColor = useColorModeValue('#00000014', '#FFFFFF0A')

	return tabs.map((tab) => (
		<Box key={tab.label} width='250px' backgroundColor={tab.label === expandedTab && expandedTabColor}>
			<ChakraLink
				onClick={() => setExpandedTab(tab.label)}
				as={NavLink}
				to={tab.children ? tab.children[0].path : tab.path}
				display='flex'
				alignItems='center'
				gap='3'
				_hover={{ backgroundColor: tab.isParent ? expandedTabColor : selectedTabColor }}
				style={({ isActive }) => ({
					backgroundColor: isActive && !tab.isParent && selectedTabColor,
					padding: '13px',
					paddingLeft: tab.isParent ? '13px' : '30px',
					borderLeft: `4px solid ${isActive && !tab.isParent ? lightBlueColor : 'transparent'}`,
					fontWeight: isActive || tab.label === expandedTab ? '500' : 'normal',
				})}>
				<tab.icon color={tab.isParent && tab.label === expandedTab && lightBlueColor} boxSize={tab.isParent ? '6' : '5'} />
				<Text fontSize='sm'>{tab.label}</Text>
			</ChakraLink>
			{tab.children && tab.label === expandedTab && <SideBarList tabs={tab.children} />}
		</Box>
	))
}

export default SideBarList
