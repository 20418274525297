export const ApplicationIcon = (props) => (
	<svg viewBox='0 0 24 24' fill='currentColor' height='1em' width='1em' {...props}>
		<path d='M21 2H3c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m0 5H3V4h18v3z' />
	</svg>
)
export const StatusIcon = (props) => (
	<svg viewBox='0 0 24 24' fill='currentColor' height='1em' width='1em' {...props}>
		<path d='M16.5 11L13 7.5l1.4-1.4 2.1 2.1L20.7 4l1.4 1.4-5.6 5.6M11 7H2v2h9V7m10 6.4L19.6 12 17 14.6 14.4 12 13 13.4l2.6 2.6-2.6 2.6 1.4 1.4 2.6-2.6 2.6 2.6 1.4-1.4-2.6-2.6 2.6-2.6M11 15H2v2h9v-2z' />
	</svg>
)

export const DescriptionIcon = (props) => (
	<svg
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		viewBox='0 0 24 24'
		height='1em'
		width='1em'
		{...props}>
		<path stroke='none' d='M0 0h24v24H0z' />
		<path d='M14 3v4a1 1 0 001 1h4' />
		<path d='M17 21H7a2 2 0 01-2-2V5a2 2 0 012-2h7l5 5v11a2 2 0 01-2 2zM9 17h6M9 13h6' />
	</svg>
)

export const PackageIcon = (props) => (
	<svg
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		viewBox='0 0 24 24'
		height='1em'
		width='1em'
		{...props}>
		<path d='M16.5 9.4l-9-5.19M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z' />
		<path d='M3.27 6.96L12 12.01l8.73-5.05M12 22.08V12' />
	</svg>
)

export const SlugIcon = (props) => (
	<svg viewBox='0 0 24 24' fill='currentColor' height='1em' width='1em' {...props}>
		<path d='M3 5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5m9.5 6h-1A1.5 1.5 0 0110 9.5 1.5 1.5 0 0111.5 8h1A1.5 1.5 0 0114 9.5h2A3.5 3.5 0 0012.5 6h-1A3.5 3.5 0 008 9.5a3.5 3.5 0 003.5 3.5h1a1.5 1.5 0 011.5 1.5 1.5 1.5 0 01-1.5 1.5h-1a1.5 1.5 0 01-1.5-1.5H8a3.5 3.5 0 003.5 3.5h1a3.5 3.5 0 003.5-3.5 3.5 3.5 0 00-3.5-3.5z' />
	</svg>
)

export const ColorIcon = (props) => (
	<svg viewBox='0 0 1024 1024' fill='currentColor' height='1em' width='1em' {...props}>
		<path d='M766.4 744.3c43.7 0 79.4-36.2 79.4-80.5 0-53.5-79.4-140.8-79.4-140.8S687 610.3 687 663.8c0 44.3 35.7 80.5 79.4 80.5zm-377.1-44.1c7.1 7.1 18.6 7.1 25.6 0l256.1-256c7.1-7.1 7.1-18.6 0-25.6l-256-256c-.6-.6-1.3-1.2-2-1.7l-78.2-78.2a9.11 9.11 0 00-12.8 0l-48 48a9.11 9.11 0 000 12.8l67.2 67.2-207.8 207.9c-7.1 7.1-7.1 18.6 0 25.6l255.9 256zm12.9-448.6l178.9 178.9H223.4l178.8-178.9zM904 816H120c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8z' />
	</svg>
)

export const NumberIcon = (props) => (
	<svg viewBox='0 0 1024 1024' fill='currentColor' height='1em' width='1em' {...props}>
		<path d='M872 394c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H400V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v236H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h228v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h164c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V394h164zM628 630H400V394h228v236z' />
	</svg>
)
