import { Icon } from '@chakra-ui/react'

export const IconThreeDotsVertical = (props) => (
	<Icon viewBox='0 0 16 16' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M9.5 13a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'
		/>
	</Icon>
)

export const IconCompanyOutlined = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M15 9h2v2h-2V9m2-4h-2v2h2V5m-6 10h2v-2h-2v2m2-10h-2v2h2V5m-2 6h2V9h-2v2M9 5H7v2h2V5m0 4H7v2h2V9m5.55 12H13v-3.5h-2V21H5V3h14v8.03c.71.06 1.39.28 2 .6V1H3v22h12.91c-.41-.56-.91-1.24-1.36-2M7 19h2v-2H7v2m2-6H7v2h2v-2m13 3.5c0 2.6-3.5 6.5-3.5 6.5S15 19.1 15 16.5c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5m-2.3.1c0-.6-.6-1.2-1.2-1.2s-1.2.5-1.2 1.2c0 .6.5 1.2 1.2 1.2s1.3-.6 1.2-1.2z'
		/>
	</Icon>
)

export const IconCompanyFilled = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M15.19 21C14.12 19.43 13 17.36 13 15.5c0-1.83.96-3.5 2.4-4.5H15V9h2v1.23c.5-.14 1-.23 1.5-.23.17 0 .34 0 .5.03V3H5v18h6v-3.5h2V21h2.19M15 5h2v2h-2V5M9 19H7v-2h2v2m0-4H7v-2h2v2m0-4H7V9h2v2m0-4H7V5h2v2m2-2h2v2h-2V5m0 4h2v2h-2V9m0 6v-2h2v2h-2m7.5-3c-1.9 0-3.5 1.61-3.5 3.5 0 2.61 3.5 6.5 3.5 6.5s3.5-3.89 3.5-6.5c0-1.89-1.6-3.5-3.5-3.5m0 4.81c-.7 0-1.2-.6-1.2-1.2 0-.7.6-1.2 1.2-1.2s1.2.59 1.2 1.2c.1.6-.5 1.2-1.2 1.2z'
		/>
	</Icon>
)

export const IconHospitalOutlined = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path fill='none' d='M0 0h24v24H0z' />
		<path
			fill='currentColor'
			d='M18 14h-4v4h-4v-4H6v-4h4V6h4v4h4m2-8H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m0 18H4V4h16v16z'
		/>
	</Icon>
)

export const IconHospitalFilled = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path fill='none' d='M0 0h24v24H0z' />
		<path
			fill='currentColor'
			d='M18 14h-4v4h-4v-4H6v-4h4V6h4v4h4m1-7H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2z'
		/>
	</Icon>
)

export const UploadIconOutlined = (props) => (
	<Icon viewBox='0 0 1024 1024' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z'
		/>
	</Icon>
)

export const DownloadIconOutlined = (props) => (
	<Icon viewBox='0 0 1024 1024' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M505.7 661a8 8 0 0012.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z'
		/>
	</Icon>
)

export const AddIconOutlined = (props) => (
	<Icon viewBox='0 0 20 20' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16 8 8 0 000 16z'
		/>
	</Icon>
)

export const AddIconFilled = (props) => (
	<Icon viewBox='0 0 20 20' boxSize={5} {...props}>
		<path fill='currentColor' d='M11 9V5H9v4H5v2h4v4h2v-4h4V9h-4zm-1 11a10 10 0 110-20 10 10 0 010 20z' />
	</Icon>
)

export const TransferIconOutlined = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path fill='currentColor' d='M15 12l5-4-5-4v2.999H2v2h13zm7 3H9v-3l-5 4 5 4v-3h13z' />
	</Icon>
)

export const FilterIconOutlined = (props) => (
	<Icon viewBox='0 0 1024 1024' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M880.1 154H143.9c-24.5 0-39.8 26.7-27.5 48L349 597.4V838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V597.4L907.7 202c12.2-21.3-3.1-48-27.6-48zM603.4 798H420.6V642h182.9v156zm9.6-236.6l-9.5 16.6h-183l-9.5-16.6L212.7 226h598.6L613 561.4z'
		/>
	</Icon>
)

export const FilterIconFilled = (props) => (
	<Icon viewBox='0 0 1024 1024' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z'
		/>
	</Icon>
)

export const ForwardIcon = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M15.038 6.343l-1.411 1.418 3.27 3.255-13.605.013.002 2 13.568-.013-3.215 3.23 1.417 1.41 5.644-5.67-5.67-5.643z'
		/>
	</Icon>
)

export const ForwardShortIcon = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path fill='currentColor' d='M4 11v2h12l-5.5 5.5 1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5 16 11H4z' />
	</Icon>
)

export const BackwardShortIcon = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path fill='currentColor' d='M20 11v2H8l5.5 5.5-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5 8 11h12z' />
	</Icon>
)
export const BackwardIcon = (props) => (
	<Icon viewBox='0 0 24 24' boxSize={5} {...props}>
		<path fill='currentColor' d='M20.328 11v2H7.5l3.243 3.243-1.415 1.414L3.672 12l5.656-5.657 1.415 1.414L7.5 11h12.828z' />
	</Icon>
)

export const RepeatIcon = (props) => (
	<Icon
		viewBox='0 0 24 24'
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		boxSize={5}
		{...props}>
		<path stroke='none' d='M0 0h24v24H0z' />
		<path d='M4 12V9a3 3 0 013-3h13m-3-3l3 3-3 3M20 12v3a3 3 0 01-3 3H4m3 3l-3-3 3-3' />
	</Icon>
)

export const PersonFilledIcon = (props) => (
	<Icon viewBox='0 0 512 512' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M332.64 64.58C313.18 43.57 286 32 256 32c-30.16 0-57.43 11.5-76.8 32.38-19.58 21.11-29.12 49.8-26.88 80.78C156.76 206.28 203.27 256 256 256s99.16-49.71 103.67-110.82c2.27-30.7-7.33-59.33-27.03-80.6zM432 480H80a31 31 0 01-24.2-11.13c-6.5-7.77-9.12-18.38-7.18-29.11C57.06 392.94 83.4 353.61 124.8 326c36.78-24.51 83.37-38 131.2-38s94.42 13.5 131.2 38c41.4 27.6 67.74 66.93 76.18 113.75 1.94 10.73-.68 21.34-7.18 29.11A31 31 0 01432 480z'
		/>
	</Icon>
)

export const DevicesIconOutlined = (props) => (
	<Icon
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		viewBox='0 0 24 24'
		boxSize={5}
		{...props}>
		<path stroke='none' d='M0 0h24v24H0z' />
		<path d='M14 8 H20 A1 1 0 0 1 21 9 V19 A1 1 0 0 1 20 20 H14 A1 1 0 0 1 13 19 V9 A1 1 0 0 1 14 8 z' />
		<path d='M18 8V5a1 1 0 00-1-1H4a1 1 0 00-1 1v12a1 1 0 001 1h9M16 9h2' />
	</Icon>
)

export const AppsIconOutlined = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M16 20h4v-4h-4m0-2h4v-4h-4m-6-2h4V4h-4m6 4h4V4h-4m-6 10h4v-4h-4m-6 4h4v-4H4m0 10h4v-4H4m6 4h4v-4h-4M4 8h4V4H4v4z' />
	</Icon>
)

export const SearchIcon = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z' />
	</Icon>
)

export const CancelIconFilled = (props) => (
	<Icon baseProfile='tiny' viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm-5 8c0-.832.224-1.604.584-2.295l6.711 6.711A4.943 4.943 0 0112 17c-2.757 0-5-2.243-5-5zm9.416 2.295L9.705 7.584A4.943 4.943 0 0112 7c2.757 0 5 2.243 5 5 0 .832-.224 1.604-.584 2.295z' />
	</Icon>
)

export const CancelIconOutline = (props) => (
	<Icon baseProfile='tiny' viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M12 20.5c-4.688 0-8.5-3.812-8.5-8.5s3.812-8.5 8.497-8.5c4.69 0 8.503 3.812 8.503 8.5s-3.812 8.5-8.5 8.5zm0-15c-3.586 0-6.5 2.916-6.5 6.5s2.916 6.5 6.5 6.5 6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5zm.003 3A3.502 3.502 0 0115.5 12c0 .206-.02.412-.057.615l-4.057-4.059c.203-.036.408-.056.614-.056m.003-1a4.48 4.48 0 00-2.39.697l6.188 6.188a4.448 4.448 0 00.699-2.387A4.5 4.5 0 0012.003 7.5zm-3.446 3.884l4.059 4.06A3.504 3.504 0 018.5 11.998c0-.206.02-.412.057-.614m-.358-1.773a4.48 4.48 0 00-.699 2.387A4.502 4.502 0 0012 16.5a4.48 4.48 0 002.387-.699l-6.188-6.19z' />
	</Icon>
)

export const ViewIconOutline = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M17.56 17.66a8 8 0 01-11.32 0L1.3 12.7a1 1 0 010-1.42l4.95-4.95a8 8 0 0111.32 0l4.95 4.95a1 1 0 010 1.42l-4.95 4.95zm-9.9-1.42a6 6 0 008.48 0L20.38 12l-4.24-4.24a6 6 0 00-8.48 0L3.4 12l4.25 4.24zM11.9 16a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4z' />
	</Icon>
)

export const ViewIconFilled = (props) => (
	<Icon viewBox='0 0 20 20' fill='currentColor' boxSize={5} {...props}>
		<path d='M.2 10a11 11 0 0119.6 0A11 11 0 01.2 10zm9.8 4a4 4 0 100-8 4 4 0 000 8zm0-2a2 2 0 110-4 2 2 0 010 4z' />
	</Icon>
)

export const ActivityLogIconOutline = (props) => (
	<Icon fill='none' viewBox='0 0 15 15' boxSize={5} {...props}>
		<path
			fill='currentColor'
			fillRule='evenodd'
			d='M0 1.5A.5.5 0 01.5 1h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zm4 0a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm-4 3A.5.5 0 01.5 7h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zm4 0a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm-4 3a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zm4 0a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10a.5.5 0 01-.5-.5z'
			clipRule='evenodd'
		/>
	</Icon>
)

export const NotificationCircleIcon = (props) => (
	<Icon viewBox='0 0 16 16' fill='currentColor' boxSize={5} {...props}>
		<path
			fill='currentColor'
			d='M8 1.5c-1.736 0-3.369.676-4.596 1.904S1.5 6.264 1.5 8c0 1.736.676 3.369 1.904 4.596S6.264 14.5 8 14.5c1.736 0 3.369-.676 4.596-1.904S14.5 9.736 14.5 8c0-1.736-.676-3.369-1.904-4.596S9.736 1.5 8 1.5zM8 0a8 8 0 110 16A8 8 0 018 0zM7 11h2v2H7zm0-8h2v6H7z'
		/>
	</Icon>
)

export const CloseIconOutline = (props) => (
	<Icon viewBox='0 0 512 512' fill='currentColor' boxSize={5} {...props}>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={32}
			d='M368 368L144 144M368 144L144 368'
		/>
	</Icon>
)

export const CloseIconFilled = (props) => (
	<Icon viewBox='0 0 512 512' fill='currentColor' boxSize={5} {...props}>
		<path d='M400 145.49L366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z' />
	</Icon>
)

export const DeleteIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z' />
	</Icon>
)

export const DeleteIconFilled = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z' />
	</Icon>
)

export const CopyIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z' />
	</Icon>
)

export const CopyIconFilled = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM382 896h-.2L232 746.2v-.2h150v150z' />
	</Icon>
)

export const ArrowDownIconOutline = (props) => (
	<Icon viewBox='0 0 512 512' fill='currentColor' boxSize={5} {...props}>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={48}
			d='M112 268l144 144 144-144M256 392V100'
		/>
	</Icon>
)

export const USBIconOutline = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path fill='none' d='M0 0h24v24H0z' />
		<path d='M12 1l3 5h-2v7.381l3-1.499-.001-.882H15V7h4v4h-1.001L18 13.118l-5 2.5v1.553a3 3 0 11-2.31.129L6 14l-.001-2.268a2 2 0 112.001 0V13l3 2.086V6H9l3-5zm0 18a1 1 0 100 2 1 1 0 000-2z' />
	</Icon>
)

export const ChevronUpIcon = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M6.293 13.293l1.414 1.414L12 10.414l4.293 4.293 1.414-1.414L12 7.586z' />
	</Icon>
)

export const ChevronDownIcon = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M16.293 9.293L12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z' />
	</Icon>
)

export const DocumentAttachOutline = (props) => (
	<Icon viewBox='0 0 512 512' fill='currentColor' boxSize={5} {...props}>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={32}
			d='M208 64h66.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62V432a48 48 0 01-48 48H192a48 48 0 01-48-48V304'
		/>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={32}
			d='M288 72v120a32 32 0 0032 32h120'
		/>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeMiterlimit={10}
			strokeWidth={32}
			d='M160 80v152a23.69 23.69 0 01-24 24c-12 0-24-9.1-24-24V88c0-30.59 16.57-56 48-56s48 24.8 48 55.38v138.75c0 43-27.82 77.87-72 77.87s-72-34.86-72-77.87V144'
		/>
	</Icon>
)

export const DocumentAttachFilled = (props) => (
	<Icon viewBox='0 0 512 512' fill='currentColor' boxSize={5} {...props}>
		<path d='M460 240H320a48 48 0 01-48-48V52a4 4 0 00-4-4h-53.25a65.42 65.42 0 00-6.5-9.81C196.72 23.88 179.59 16 160 16c-37.68 0-64 29.61-64 72v144c0 25 20.34 40 40 40a39.57 39.57 0 0040-40V80a16 16 0 00-32 0v152a7.75 7.75 0 01-8 8c-2.23 0-8-1.44-8-8V88c0-19.34 8.41-40 32-40 29.69 0 32 30.15 32 39.38v138.75c0 17.45-5.47 33.23-15.41 44.46C166.5 282 152.47 288 136 288s-30.5-6-40.59-17.41C85.47 259.36 80 243.58 80 226.13V144a16 16 0 00-32 0v82.13c0 51.51 33.19 89.63 80 93.53V432a64 64 0 0064 64h208a64 64 0 0064-64V244a4 4 0 00-4-4z' />
		<path d='M320 208h129.81a2 2 0 001.41-3.41L307.41 60.78a2 2 0 00-3.41 1.41V192a16 16 0 0016 16z' />
	</Icon>
)

export const DashboardIconOutline = (props) => (
	<Icon
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		viewBox='0 0 24 24'
		boxSize={5}
		{...props}>
		<path d='M4 3 H9 A1 1 0 0 1 10 4 V11 A1 1 0 0 1 9 12 H4 A1 1 0 0 1 3 11 V4 A1 1 0 0 1 4 3 z' />
		<path d='M15 3 H20 A1 1 0 0 1 21 4 V7 A1 1 0 0 1 20 8 H15 A1 1 0 0 1 14 7 V4 A1 1 0 0 1 15 3 z' />
		<path d='M15 12 H20 A1 1 0 0 1 21 13 V20 A1 1 0 0 1 20 21 H15 A1 1 0 0 1 14 20 V13 A1 1 0 0 1 15 12 z' />
		<path d='M4 16 H9 A1 1 0 0 1 10 17 V20 A1 1 0 0 1 9 21 H4 A1 1 0 0 1 3 20 V17 A1 1 0 0 1 4 16 z' />
	</Icon>
)

export const UserCheckIconOutline = (props) => (
	<Icon
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		viewBox='0 0 24 24'
		boxSize={5}
		{...props}>
		<path stroke='none' d='M0 0h24v24H0z' />
		<path d='M13 7 A4 4 0 0 1 9 11 A4 4 0 0 1 5 7 A4 4 0 0 1 13 7 z' />
		<path d='M3 21v-2a4 4 0 014-4h4a4 4 0 014 4v2M16 11l2 2 4-4' />
	</Icon>
)

export const UserIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z' />
	</Icon>
)

export const SettingsIconOutline = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M19.9 12.66a1 1 0 010-1.32l1.28-1.44a1 1 0 00.12-1.17l-2-3.46a1 1 0 00-1.07-.48l-1.88.38a1 1 0 01-1.15-.66l-.61-1.83a1 1 0 00-.95-.68h-4a1 1 0 00-1 .68l-.56 1.83a1 1 0 01-1.15.66L5 4.79a1 1 0 00-1 .48L2 8.73a1 1 0 00.1 1.17l1.27 1.44a1 1 0 010 1.32L2.1 14.1a1 1 0 00-.1 1.17l2 3.46a1 1 0 001.07.48l1.88-.38a1 1 0 011.15.66l.61 1.83a1 1 0 001 .68h4a1 1 0 00.95-.68l.61-1.83a1 1 0 011.15-.66l1.88.38a1 1 0 001.07-.48l2-3.46a1 1 0 00-.12-1.17zM18.41 14l.8.9-1.28 2.22-1.18-.24a3 3 0 00-3.45 2L12.92 20h-2.56L10 18.86a3 3 0 00-3.45-2l-1.18.24-1.3-2.21.8-.9a3 3 0 000-4l-.8-.9 1.28-2.2 1.18.24a3 3 0 003.45-2L10.36 4h2.56l.38 1.14a3 3 0 003.45 2l1.18-.24 1.28 2.22-.8.9a3 3 0 000 3.98zm-6.77-6a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z' />
	</Icon>
)

export const ProfileIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656zM492 400h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H492c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 144h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H492c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 144h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H492c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zM340 368a40 40 0 1080 0 40 40 0 10-80 0zm0 144a40 40 0 1080 0 40 40 0 10-80 0zm0 144a40 40 0 1080 0 40 40 0 10-80 0z' />
	</Icon>
)

export const FloorIconOutline = (props) => (
	<Icon fill='none' viewBox='0 0 15 15' boxSize={5} {...props}>
		<path
			fill='currentColor'
			fillRule='evenodd'
			d='M0 0h4.651l3.126 2.084-.554.832L4.349 1H1v13h5V8H4V7h5v1H7v6h7V8h-2V7h2V1h-4V0h5v15H0V0z'
			clipRule='evenodd'
		/>
	</Icon>
)

export const DevicesIconOutline = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M20 3H7c-1.103 0-2 .897-2 2v2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h6c1.103 0 2-.897 2-2h8c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM9.997 19H4V9h6l-.003 10zm10-2H12V9c0-1.103-.897-2-2-2H7V5h13l-.003 12z' />
	</Icon>
)

export const MoonIconOutline = (props) => (
	<Icon viewBox='0 0 512 512' fill='currentColor' boxSize={5} {...props}>
		<path
			fill='none'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={32}
			d='M160 136c0-30.62 4.51-61.61 16-88C99.57 81.27 48 159.32 48 248c0 119.29 96.71 216 216 216 88.68 0 166.73-51.57 200-128-26.39 11.49-57.38 16-88 16-119.29 0-216-96.71-216-216z'
		/>
	</Icon>
)

export const SunIconOutline = (props) => (
	<Icon fill='currentColor' viewBox='0 0 16 16' boxSize={5} {...props}>
		<path d='M8 11a3 3 0 110-6 3 3 0 010 6zm0 1a4 4 0 100-8 4 4 0 000 8zM8 0a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2A.5.5 0 018 0zm0 13a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2A.5.5 0 018 13zm8-5a.5.5 0 01-.5.5h-2a.5.5 0 010-1h2a.5.5 0 01.5.5zM3 8a.5.5 0 01-.5.5h-2a.5.5 0 010-1h2A.5.5 0 013 8zm10.657-5.657a.5.5 0 010 .707l-1.414 1.415a.5.5 0 11-.707-.708l1.414-1.414a.5.5 0 01.707 0zm-9.193 9.193a.5.5 0 010 .707L3.05 13.657a.5.5 0 01-.707-.707l1.414-1.414a.5.5 0 01.707 0zm9.193 2.121a.5.5 0 01-.707 0l-1.414-1.414a.5.5 0 01.707-.707l1.414 1.414a.5.5 0 010 .707zM4.464 4.465a.5.5 0 01-.707 0L2.343 3.05a.5.5 0 11.707-.707l1.414 1.414a.5.5 0 010 .708z' />
	</Icon>
)

export const CloudSyncIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M811.4 368.9C765.6 248 648.9 162 512.2 162S258.8 247.9 213 368.8C126.9 391.5 63.5 470.2 64 563.6 64.6 668 145.6 752.9 247.6 762c4.7.4 8.7-3.3 8.7-8v-60.4c0-4-3-7.4-7-7.9-27-3.4-52.5-15.2-72.1-34.5-24-23.5-37.2-55.1-37.2-88.6 0-28 9.1-54.4 26.2-76.4 16.7-21.4 40.2-36.9 66.1-43.7l37.9-10 13.9-36.7c8.6-22.8 20.6-44.2 35.7-63.5 14.9-19.2 32.6-36 52.4-50 41.1-28.9 89.5-44.2 140-44.2s98.9 15.3 140 44.3c19.9 14 37.5 30.8 52.4 50 15.1 19.3 27.1 40.7 35.7 63.5l13.8 36.6 37.8 10c54.2 14.4 92.1 63.7 92.1 120 0 33.6-13.2 65.1-37.2 88.6-19.5 19.2-44.9 31.1-71.9 34.5-4 .5-6.9 3.9-6.9 7.9V754c0 4.7 4.1 8.4 8.8 8 101.7-9.2 182.5-94 183.2-198.2.6-93.4-62.7-172.1-148.6-194.9z' />
		<path d='M376.9 656.4c1.8-33.5 15.7-64.7 39.5-88.6 25.4-25.5 60-39.8 96-39.8 36.2 0 70.3 14.1 96 39.8 1.4 1.4 2.7 2.8 4.1 4.3l-25 19.6a8 8 0 003 14.1l98.2 24c5 1.2 9.9-2.6 9.9-7.7l.5-101.3c0-6.7-7.6-10.5-12.9-6.3L663 532.7c-36.6-42-90.4-68.6-150.5-68.6-107.4 0-195 85.1-199.4 191.7-.2 4.5 3.4 8.3 8 8.3H369c4.2-.1 7.7-3.4 7.9-7.7zM703 664h-47.9c-4.2 0-7.7 3.3-8 7.6-1.8 33.5-15.7 64.7-39.5 88.6-25.4 25.5-60 39.8-96 39.8-36.2 0-70.3-14.1-96-39.8-1.4-1.4-2.7-2.8-4.1-4.3l25-19.6a8 8 0 00-3-14.1l-98.2-24c-5-1.2-9.9 2.6-9.9 7.7l-.4 101.4c0 6.7 7.6 10.5 12.9 6.3l23.2-18.2c36.6 42 90.4 68.6 150.5 68.6 107.4 0 195-85.1 199.4-191.7.2-4.5-3.4-8.3-8-8.3z' />
	</Icon>
)

export const AndroidIconOutline = (props) => (
	<Icon fill='none' viewBox='0 0 15 15' boxSize={5} {...props}>
		<path stroke='currentColor' d='M2.5 2.5l2 3m8-3l-2 3M4 9.5h1m5 0h1m-9.5 3v-2a6 6 0 1112 0v2h-12z' />
	</Icon>
)

export const AppAddIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<defs>
			<style />
		</defs>
		<path d='M464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H212V212h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H612V212h200v200zm52 132H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H612V612h200v200zM424 712H296V584c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v128H104c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h128v128c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V776h128c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z' />
	</Icon>
)

export const AIFileIconOutline = (props) => (
	<Icon fill='currentColor' viewBox='0 0 16 16' boxSize={5} {...props}>
		<path
			fillRule='evenodd'
			d='M14 4.5V14a2 2 0 01-2 2H6v-1h6a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5L14 4.5zM1.113 14.82L.8 15.85H0l1.342-3.999h.926l1.336 3.999h-.841l-.314-1.028H1.113zm1.178-.588l-.49-1.617h-.034l-.49 1.617h1.014zm2.425-2.382v3.999h-.791V11.85h.79z'
		/>
	</Icon>
)

export const DeviceManagementIconOutline = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M22 17h-4v-7h4m1-2h-6a1 1 0 00-1 1v10a1 1 0 001 1h6a1 1 0 001-1V9a1 1 0 00-1-1M4 6h18V4H4a2 2 0 00-2 2v11H0v3h14v-3H4V6z' />
	</Icon>
)

export const FlavorIconOutline = (props) => (
	<Icon fill='currentColor' viewBox='0 0 16 16' boxSize={5} {...props}>
		<path d='M8.235 1.559a.5.5 0 00-.47 0l-7.5 4a.5.5 0 000 .882L3.188 8 .264 9.559a.5.5 0 000 .882l7.5 4a.5.5 0 00.47 0l7.5-4a.5.5 0 000-.882L12.813 8l2.922-1.559a.5.5 0 000-.882l-7.5-4zm3.515 7.008L14.438 10 8 13.433 1.562 10 4.25 8.567l3.515 1.874a.5.5 0 00.47 0l3.515-1.874zM8 9.433L1.562 6 8 2.567 14.438 6 8 9.433z' />
	</Icon>
)

export const ReleaseIconOutline = (props) => (
	<Icon viewBox='0 0 1024 1024' fill='currentColor' boxSize={5} {...props}>
		<path d='M888.3 693.2c-42.5-24.6-94.3-18-129.2 12.8l-53-30.7V523.6c0-15.7-8.4-30.3-22-38.1l-136-78.3v-67.1c44.2-15 76-56.8 76-106.1 0-61.9-50.1-112-112-112s-112 50.1-112 112c0 49.3 31.8 91.1 76 106.1v67.1l-136 78.3c-13.6 7.8-22 22.4-22 38.1v151.6l-53 30.7c-34.9-30.8-86.8-37.4-129.2-12.8-53.5 31-71.7 99.4-41 152.9 30.8 53.5 98.9 71.9 152.2 41 42.5-24.6 62.7-73 53.6-118.8l48.7-28.3 140.6 81c6.8 3.9 14.4 5.9 22 5.9s15.2-2 22-5.9L674.5 740l48.7 28.3c-9.1 45.7 11.2 94.2 53.6 118.8 53.3 30.9 121.5 12.6 152.2-41 30.8-53.6 12.6-122-40.7-152.9zm-673 138.4a47.6 47.6 0 01-65.2-17.6c-13.2-22.9-5.4-52.3 17.5-65.5a47.6 47.6 0 0165.2 17.6c13.2 22.9 5.4 52.3-17.5 65.5zM522 463.8zM464 234a48.01 48.01 0 0196 0 48.01 48.01 0 01-96 0zm170 446.2l-122 70.3-122-70.3V539.8l122-70.3 122 70.3v140.4zm239.9 133.9c-13.2 22.9-42.4 30.8-65.2 17.6-22.8-13.2-30.7-42.6-17.5-65.5s42.4-30.8 65.2-17.6c22.9 13.2 30.7 42.5 17.5 65.5z' />
	</Icon>
)

export const AppReleaseIconOutline = (props) => (
	<Icon
		fill='none'
		stroke='currentColor'
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={2}
		viewBox='0 0 24 24'
		boxSize={5}
		{...props}>
		<path stroke='none' d='M0 0h24v24H0z' />
		<path d='M5 5 H19 A2 2 0 0 1 21 7 V17 A2 2 0 0 1 19 19 H5 A2 2 0 0 1 3 17 V7 A2 2 0 0 1 5 5 z' />
		<path d='M6 8h.01M9 8h.01' />
	</Icon>
)

export const HeadLearningIconOutline = (props) => (
	<Icon viewBox='0 0 24 24' fill='currentColor' boxSize={5} {...props}>
		<path d='M13 3c3.88 0 7 3.14 7 7 0 2.8-1.63 5.19-4 6.31V21H9v-3H8c-1.11 0-2-.89-2-2v-3H4.5c-.42 0-.66-.5-.42-.81L6 9.66A7.003 7.003 0 0113 3m0-2C8.41 1 4.61 4.42 4.06 8.9L2.5 11h-.03l-.02.03c-.55.76-.62 1.76-.19 2.59.36.69 1 1.17 1.74 1.32V16c0 1.85 1.28 3.42 3 3.87V23h11v-5.5c2.5-1.67 4-4.44 4-7.5 0-4.97-4.04-9-9-9m1 14v-1.5c-2.21 0-4-1.79-4-4 0-.82.25-1.58.67-2.21l1.09 1.09c-.17.34-.26.72-.26 1.12A2.5 2.5 0 0014 12v-1.5l2.25 2.25L14 15m3.33-3.29l-1.09-1.09c.17-.34.26-.72.26-1.12A2.5 2.5 0 0014 7v1.5l-2.25-2.25L14 4v1.5c2.21 0 4 1.79 4 4 0 .82-.25 1.58-.67 2.21z' />
	</Icon>
)

export const CreateIconOutline = (props) => (
	<Icon viewBox='0 0 21 21' fill='currentColor' boxSize={5} {...props}>
		<g fill='none' fillRule='evenodd' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'>
			<path d='M10 4.5H5.5a2 2 0 00-2 2v9a2 2 0 002 2h10a2 2 0 002-2V11' />
			<path d='M17.5 3.467a1.462 1.462 0 01-.017 2.05L10.5 12.5l-3 1 1-3 6.987-7.046a1.409 1.409 0 011.885-.104zM15.5 5.5l.953 1' />
		</g>
	</Icon>
)

export const ChevronRightIcon = (props) => (
	<Icon fill='currentColor' viewBox='0 0 16 16' boxSize={5} {...props}>
		<path
			fillRule='evenodd'
			d='M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z'
		/>
	</Icon>
)
