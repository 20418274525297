const handleCopyValue = (stringValue, toast) => {
	navigator.clipboard.writeText(stringValue).then(
		() => {
			toast({
				title: 'Copy Value',
				description: stringValue ? `${stringValue} has been copied` : `Failed to copy non existing value `,
				status: stringValue ? 'success' : 'error',
				duration: 2000,
				isClosable: true,
			})
		},
		() => {
			toast({
				title: 'Copy Value',
				description: `Failed to copy ${stringValue} `,
				status: 'error',
				duration: 2000,
				isClosable: true,
			})
		}
	)
}
export default handleCopyValue
