import { IconButton, useDisclosure } from '@chakra-ui/react'
import { GlobalSearchModal } from 'components'
import { SearchIcon } from 'icons/global'

const GlobalSearch = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<IconButton icon={<SearchIcon style={{ margin: '0' }} />} onClick={onOpen} variant='ghost' rounded='full' />
			{isOpen && <GlobalSearchModal isOpen={isOpen} onClose={onClose} />}
		</>
	)
}

export default GlobalSearch
