import {
	Avatar,
	Box,
	HStack,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Tag,
	Text,
	useColorMode,
	useColorModeValue,
} from '@chakra-ui/react'
import { getUserRole } from 'helpers'
import { IconLogoutCircleRLine, MoonIconOutline, SunIconOutline } from 'icons/global'
import GlobalSearch from 'layout/GlobarSearch'
import { newTabs } from 'layout/routeTabs'
import SidebarList from 'layout/SideBarList'
import { useAuth } from 'oidc-react'
import { useCallback, useMemo } from 'react'

const cdnLogo = `${process.env.REACT_APP_CDN_URL}global/brand/assets/logos/solaborate-s-only-logo.png`

const Sidebar = () => {
	const { colorMode, toggleColorMode } = useColorMode()
	const {
		userManager,
		userData: { profile },
	} = useAuth()

	const handleSignout = useCallback(() => {
		userManager.removeUser()
		userManager.signoutRedirect()
		localStorage.clear()
	}, [])

	const authorizedRoutes = useMemo(() => {
		return newTabs.filter((item) =>
			item.roles.some((item) => (profile['sub.role'] ? profile['sub.role'].includes(item) : profile.role?.includes(item)))
		)
	}, [])

	const role = getUserRole(profile.role)

	const sidebarBackground = useColorModeValue('#FFFFFF', '#1A1A1A')
	const borderColor = useColorModeValue('#E2E8F0', '#FFFFFF29')
	const isDarkMode = colorMode === 'dark'

	return (
		<Stack borderRight={`1px solid ${borderColor}`} bg={sidebarBackground} justifyContent='space-between'>
			<Box>
				<HStack justifyContent='space-between' m='4' pb='1' borderBottom={`1px solid ${borderColor}`}>
					<HStack>
						<Image boxSize='27px' src={cdnLogo} alt='Solaborate' />
						<Text fontWeight='500'>Solaborate</Text>
					</HStack>
					<GlobalSearch />
				</HStack>
				<SidebarList tabs={authorizedRoutes} />
			</Box>
			<HStack m='4' spacing='3' pt='3' borderTop={`1px solid ${borderColor}`}>
				<Menu>
					<MenuButton>
						<Avatar name={`${profile.given_name} ${profile.family_name}`} />
					</MenuButton>
					<MenuList>
						<MenuItem
							icon={isDarkMode ? <MoonIconOutline fontSize='20' /> : <SunIconOutline fontSize='20' />}
							onClick={toggleColorMode}>
							{isDarkMode ? 'Dark Mode' : 'Light Mode'}
						</MenuItem>
						<MenuItem icon={<IconLogoutCircleRLine fontSize='20' />} onClick={handleSignout}>
							Logout
						</MenuItem>
					</MenuList>
				</Menu>
				<Box>
					<Text fontWeight='500'>
						{profile.given_name} {profile.family_name}
					</Text>
					<Tag colorScheme='green'>{role}</Tag>
				</Box>
			</HStack>
		</Stack>
	)
}

export default Sidebar
