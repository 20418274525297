export const toaster = ({ title, description, status, ...rest }, callback) => {
	return callback({
		title: title ?? 'Success',
		description: description ?? 'This operation has been successfull',
		status: status ?? 'success',
		duration: 9000,
		isClosable: true,
		position: 'top',
		...rest,
	})
}

export const errorToast = (callback, title, message) =>
	callback({
		title: title ?? 'Error',
		description: message ?? 'Something went wrong when performing this action, please try again later',
		status: 'error',
		duration: 9000,
		isClosable: true,
		position: 'top',
	})
